<template>
  <iframe
    :src="`https://grupovoz.net.br/chat/chat-crm/index.php?user=${userId}`"
    width="120%"
    height="100%"
  />
</template>

<script>
export default {
  data() {
    return {
      userId: null,
    }
  },

  created() {
    const { userId } = localStorage
    this.userId = userId
  },
}
</script>
